import React from 'react'
import './card.css'
import TotalFunds from './TotalFunds'
import SchemeCard from './SchemeCard'
function Funds() {
  return (
    <div>
        <TotalFunds></TotalFunds>
        <SchemeCard></SchemeCard>
    </div>
  )
}

export default Funds