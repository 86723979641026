import React, { useEffect, useState } from 'react';
import './cardstyle.css';
import axios from 'axios';
import InfoCard from './InfoCard';
import AccountCard from './AccountCard';
import ReferalCard from './ReferalCard';
import { hideLoading, showLoading } from '../../redux/alertsSlice';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

function Profile() {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(showLoading());
        const response = await axios.post('https://api.deepfinance.co.in/api/v1/user/personal-detail',
          { userid: null },
          {
            headers: {
              'Content-Type': 'application/json',
              authorization: localStorage.getItem('token'),
            },
          }
        );
        dispatch(hideLoading());
        if (response.data.success) {
          setData(response.data.data);
        } else {
          toast.error('something went wrong');
        }
      } catch (error) {
        dispatch(hideLoading());
        toast.error('something went wrong');
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      console.log(data); // Logging the data when it's updated
    }
  }, [data]);

  return (
    <div className='profile'>
        {
            data?<><InfoCard  data={data}/>
            <AccountCard />
            <ReferalCard data={data}/></>:<></>
        }  
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default Profile;
