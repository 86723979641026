import React, { useEffect, useState } from 'react'
import './card.css'
import { hideLoading, showLoading } from '../../redux/alertsSlice';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import axios from 'axios';
function OfferCard() {
    const [statusStyle,setStatusStyle]=useState('status-pending')
    const [status,setStatus]=useState(null)
    const [mc,setMc]=useState(null)
    const dispatch = useDispatch();
    const [data, setData] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          dispatch(showLoading());
          const response = await axios.post(
            'https://api.deepfinance.co.in/api/v1/user/status-member',
            { userid: null },
            {
              headers: {
                'Content-Type': 'application/json',
                authorization: localStorage.getItem('token'),
              },
            }
          );
          dispatch(hideLoading());
          if (response.data.success) {
            setData(response.data);
          } else {
            toast.error('something went wrong');
          }
        } catch (error) {
          dispatch(hideLoading());
          toast.error('something went wrong');
        }
      };
      fetchData();
    }, [dispatch]);
  
    useEffect(() => {
      if (data) {
        setStatus(data.stat)
        setMc(data.mc)
        setStatusStyle(status)
        console.log("offer : ",data)
      }
    }, [data]);
  
  return (
    <div className='card-offer'>
        <p className='card-heading-offer'>
            Membership Status
        </p>
        <div className='info'>
        <p className={statusStyle}>Status : {status}</p>
        <p>Monthly Income : {0} ₹</p>
        <p>Current Team : {mc?<>{mc}</>:<>0</>} member</p>
        </div>
        
    </div>
  )
}

export default OfferCard