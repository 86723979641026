import React, { useState } from 'react'
import { Table} from 'antd';
import './gaincard.css'
function Gain() {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'TransactionId',
      dataIndex: 'txnid',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
  ];
  const data = [];
// for (let i = 0; i < 46; i++) {
//   data.push({
//     key: i,
//     date: `Oct-2023`,
//     txnid: `Txnab000${i}`,
//     amount: `500`,
//   });
// }


  const pagination = {
    pageSize: 5, // set the number of records per page
  };
  return (
    <div className='content-area-gain'>
       <div className='card-gain'>
        <p className='card-heading-gain'>
            Total Earnings
        </p>
        <p className='card-gain-total'>
            {0.00} ₹
        </p>
        <div className='gain-additional'>
        <p>
          Daily Income : {'100'}₹
        </p>
        </div>
      </div>
        <div className='gain-table'>
          <strong>Transaction History</strong>
        <Table  size='small'   columns={columns} dataSource={data} pagination={pagination}/>
        </div>
        <br/><br/><br/><br/><br/>
    </div>
  )
}

export default Gain