import React, { useEffect, useState } from 'react'
import {BankOutlined } from '@ant-design/icons'
import { hideLoading, showLoading } from '../../redux/alertsSlice';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import axios from 'axios';
import { Modal } from 'antd';
function AccountCard() {
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [ac,setAc]=useState(null)
    const [ifsc,setIfsc]=useState(null)
    const [upi,setUpi]=useState(null)
    const [mobile,setMobile]=useState(null)
    const [edit,setEdit]=useState(false)
    useEffect(() => {
      const fetchData = async () => {
        try {
          dispatch(showLoading());
          const response = await axios.post('https://api.deepfinance.co.in/api/v1/account/getaccount',
            { userid: null },
            {
              headers: {
                'Content-Type': 'application/json',
                authorization: localStorage.getItem('token'),
              },
            }
          );
          dispatch(hideLoading());
          if (response.data) {
            console.log("acc data :",response.data.account.length)
            if(response.data.account.length>0){
            setData(response.data.account[0]);
            setAc(data.ac)
            setIfsc(data.ifsc)
            setUpi(data.upi)
            setMobile(data.mobile)}
            else{
              toast.success('please add detail')
            setEdit(true)
            }
          } else {
            toast.info('please add detail')
            setEdit(true)
          }
        } catch (error) {
          dispatch(hideLoading());
       
        }
      };
      fetchData();
    }, [dispatch]);
  
    useEffect(() => {
      if (data) {
        setAc(data.ac)

            setIfsc(data.ifsc)
            setUpi(data.upi)
            setMobile(data.mobile)
      }
    }, [data]);
  
  return (
    <div className='card'>
        <div className='card-heading'><strong>Account</strong> &nbsp;&nbsp; <button onClick={()=>{
            setEdit(true)
            }}>Add/Edit Account</button></div>
        
        <div className='card-content'>
        <Modal
        title="Add your account detail"
        centered
        open={edit}
        onOk={async() => {
          if(ac && ifsc && upi && mobile){
            await axios.post('https://api.deepfinance.co.in/api/v1/account/addaccount',{ac:ac,mobile:mobile,upi:upi,ifsc:ifsc}
            , {headers : {
              'Content-Type': 'application/json',
              'authorization':localStorage.getItem('token'),
            }},
          ).then((res)=>{
              toast.success('detail added successfully')
            }).catch((err)=>{
              toast.error('something went wrong')}
            )
          }
          setEdit(false)}}
        onCancel={async() => setEdit(false)}
      >
        <input className='modal-input' value={ac} onChange={(e)=>{setAc(e.target.value)}} placeholder='Enter a/c no.'></input>
        <input className='modal-input' value={ifsc} onChange={(e)=>{setIfsc(e.target.value)}} placeholder='Enter ifsc code'></input>
        <input className='modal-input' value={upi} onChange={(e)=>{setUpi(e.target.value)}}placeholder='Enter upi'></input>
        <input className='modal-input' value={mobile} onChange={(e)=>{setMobile(e.target.value)}} placeholder='Enter linked mobile'></input>
        
      </Modal>
            <div className='card-content-left'>
            <BankOutlined />
            </div>
            <div className='card-content-right'>
           
                {
                    <><ul><li>A/c :  {ac}</li>
                    <li>Ifsc code : {ifsc}</li></ul></>
                }
         
            </div>
        </div>
        <div className='card-content'>
            <div className='card-content-left'>
                <i>UPI</i>
            </div>
            <div className='card-content-right'>
                {
                  <ul>
                    <li>Upi id : {upi} </li>
                    <li>Mobile : {mobile}</li>
                </ul>
                }
            </div>
        </div>
    </div>
  )
}

export default AccountCard