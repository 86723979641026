import React from 'react'
import {UserOutlined} from '@ant-design/icons'
function InfoCard({data}) {
  return (
    <div className='card'>
        <div className='card-heading'><strong>Personal Details</strong></div>
        <div className='card-content'>
            <div className='card-content-left'>
            <UserOutlined />
            </div>
            <div className='card-content-right'>
            <ul>
                <li>{data[0].fullname}</li>
                <li> {data[0].mobile} </li>
                <li> {data[0].email}</li>
            </ul>
            </div>
        </div>
    </div>
  )
}

export default InfoCard