import React from 'react'
import proficon from '../asset/logo/005-profile.png'
import offericon from '../asset/logo/009-discount.png'
import teamicon from '../asset/logo/008-employee.png'
import insurance from '../asset/logo/002-policy.png'
import invst from '../asset/logo/007-investment.png'
import gain from '../asset/logo/003-profit.png'
import deeplogo from '../asset/DeepLogo.png'
import  { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Profile from '../profile/Profile'
import Offers from '../offer/Offers'
import Funds from '../funds/Funds'
import Gain from '../gain/Gain'
import Team from '../team/Team'
import Kavach from '../kavach/Kavach'
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import {TabletOutlined} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
function Home() {
    const dispatch = useDispatch();
    const [selectMenu,setSelectedMenu]=useState('profile')
    function menu(item){
        switch(item){
            case 'profile':
                return <Profile></Profile>
            case 'offer':
                return <Offers></Offers>
            case 'funds':
                return <Funds></Funds>
            case 'gain':
                return <Gain></Gain>
            case 'team':
                return <Team></Team>
            case 'kavach':
                return <Kavach></Kavach>
            default:
                return <Profile></Profile>
        }
    }
    const [isMobile, setIsMobile] = useState(false);
    const navigate=useNavigate();
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 500); // Adjust the screen width as needed
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial check
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (
    <div className='home'>
      
        <div className='menu'>
            {isMobile?(<></>):(<div style={{'height':'100%','width':'60%','backgroundColor':'black'}}>
                <img  style={{'height':'60px','width':'30%'}} src={deeplogo} alt='loading'></img>
            </div>)}
            <div className='menu-div-button'  onClick={()=>{
                 dispatch(showLoading())   
                setTimeout(() => {
                    dispatch(hideLoading())
                }, 200);
                
                setSelectedMenu('profile')
                //dispatch(hideLoading())
                }}>
            <img src={proficon} alt='loading' style={{'height':'35px'}}></img>
            <p>Profile</p>
            </div>
            <div className='menu-div-button' onClick={()=>{
                dispatch(showLoading())   
                setTimeout(() => {
                    dispatch(hideLoading())
                }, 200);
                
                setSelectedMenu('offer')}}>
            <img src={offericon} alt='loading' style={{'height':'35px'}}></img>
            <p>Offer</p>
            </div>
            <div className='menu-div-button' onClick={()=>{
                dispatch(showLoading())   
                setTimeout(() => {
                    dispatch(hideLoading())
                }, 200);
                
                setSelectedMenu('funds')}}>
            <img src={invst} alt='loading' style={{'height':'35px'}}></img>
            <p>Funds</p>
            </div>
            <div className='menu-div-button' onClick={()=>{
                dispatch(showLoading())   
                setTimeout(() => {
                    dispatch(hideLoading())
                }, 200);
                
                setSelectedMenu('gain')}}>
            <img src={gain} alt='loading' style={{'height':'35px'}}></img>
            <p>Gain</p>
            </div>
            <div className='menu-div-button' onClick={()=>{
                dispatch(showLoading())   
                setTimeout(() => {
                    dispatch(hideLoading())
                }, 200);
                
                setSelectedMenu('team')}}>
            <img src={teamicon} alt='loading' style={{'height':'35px'}}></img>
            <p>Team</p>
            </div>
            <div className='menu-div-button' onClick={()=>{
                dispatch(showLoading())   
                setTimeout(() => {
                    dispatch(hideLoading())
                }, 200);
                
                setSelectedMenu('kavach')}}>
            <img src={insurance} alt='loading' style={{'height':'35px'}}></img>
            <p>Kavach</p>
            </div>
            
        </div>
        <div className='content'>
            {isMobile?(<div className='content-header' >
                <div className='content-header-logo'>
                <img   src={deeplogo} alt='loading'></img>
                </div>
                <div className='selected-menu'><strong>{selectMenu.charAt(0).toUpperCase()+selectMenu.slice(1)} </strong>
                <TabletOutlined /></div>
            </div>):(<></>)}
            <div className='content-container'>        
            {                               
                menu(selectMenu)
            }
             </div>
        </div>
        </div>
  )
}

export default Home