import React from 'react'
import './card.css'
function TeamCard({ac,mc,iac}) {
    
  return (
    <div className='card-team'>
        <p className='card-heading-team'>
           Your Team
        </p>
        <p className='card-team-total'>
        {mc} Member
        </p>
        <div className='team-additional'>
        <p>
        Total Active Member : <span className='active'>{ac}</span> 
        </p>
        <p>Total Inactive Member :<span className='pending'>{iac}</span> </p>
        </div>
      </div>
  )
}

export default TeamCard