import React, { useState } from 'react'
import { Link, useNavigate} from 'react-router-dom'
import logo from '../asset/DeepLogo.png'
import axios from 'axios';
import { hideLoading, showLoading } from "../../redux/alertsSlice";
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';

function Login() {
  const dispatch = useDispatch();
const navigate=useNavigate();
const [userid,setUserId]=useState('')
const [password,setPassword]=useState('')
const onFinish = async (values) => {
  try {
      dispatch(showLoading());
      const response = await axios.post('https://api.deepfinance.co.in/api/v1/user/login',{userid:userid,password:password},{headers : {
        'Content-Type': 'application/json',
      }},);
      dispatch(hideLoading());
      if (response.data.success) {
          toast.success(response.data.message)
          localStorage.setItem("token",response.data.data)
          toast.success("Redirecting to home page")
          navigate("/home")
      } else {
          toast.error(response.data.message)
      }

  } catch (error) {
      dispatch(hideLoading());
      toast.error("something went wrong")
  }
}
  return (
    <div className='login'>
        <img className='logo' src={logo} alt='loading'></img>
        <p>Login Please</p>
        <input className='log-input' type='text' placeholder='Email/Phone' onChange={(e)=>{setUserId(e.target.value)}}></input>
        <input className='log-input' type='password' placeholder='Password' onChange={(e)=>{setPassword(e.target.value)}}></input>
        <button className='log-button-submit' onClick={()=>{onFinish()}}>Login</button>
        <p>Dont have an Account?</p> <Link to='/signup'>Get Started</Link>
    </div>
  )
}

export default Login