import React, { useEffect, useState } from 'react'
import TeamCard from './TeamCard'
import { Table } from 'antd'
import { hideLoading, showLoading } from '../../redux/alertsSlice';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import axios from 'axios';
function Team() {
  
  const [mc,setMc]=useState(null)
  const [ac,setAc]=useState(null)
  const [iac,setIac]=useState(null)
  const [teamData,setTeamData]=useState(null)
    const dispatch = useDispatch();
    const [data, setData] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        try {
          dispatch(showLoading());
          const response = await axios.post(
            'https://api.deepfinance.co.in/api/v1/user/team-info',
            { userid: null },
            {
              headers: {
                'Content-Type': 'application/json',
                authorization: localStorage.getItem('token'),
              },
            }
          );
          dispatch(hideLoading());
          if (response.data.success) {
            setData(response.data);
          } else {
            toast.error('something went wrong');
          }
        } catch (error) {
          dispatch(hideLoading());
          toast.error('something went wrong');
        }
      };
      fetchData();
    }, [dispatch]);
  
    useEffect(() => {
      if (data) {
        setAc(data.activeCount)
        setIac(data.inactiveCount)
        setMc(data.mc)
        setTeamData(data.teamData)
        console.log(data)
      }
    }, [data]);
  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];
 
// for (let i = 0; i < 10; i++) {
//   data.push({
//     key: i,
//     name:  i%2===0 ?`Pappu Kumar`:`Sanjay Kishor`,
//     contact: `875963215${i}`,
//     status:  i%2===0 ?`active`:`pending`,
//   });
// }

  const pagination = {
    pageSize: 5, // set the number of records per page
  };
  return (
    <div className='team'>
        {
          data?<><TeamCard ac={ac} mc={mc} iac={iac}></TeamCard></>:<></>
        }
        
        <div className='gain-table'>
          <strong>Team Info</strong>
        <Table  size='small'   columns={columns} dataSource={teamData} pagination={pagination} />
        </div>
        <br/><br/><br/><br/><br/>
    </div>
  )
}

export default Team