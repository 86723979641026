import React from 'react'

function TotalFunds() {
  return (
    <div className='card-fund'>
        <p className='card-heading-fund'>
            Total Investment
        </p>
        <p className='card-fund-total'>
            {0.00} ₹
        </p>
        <div className='fund-aditional-detail'>
        <p>Active Scheme : {'100/day'}</p>
        </div>
        
    </div>
  )
}

export default TotalFunds