import React from 'react'
import {Button} from 'antd'
function Kavach() {
  return (
    <>
         <div className="terms-container">
            <h2 className="terms-heading">Insurance Terms and Conditions</h2>
            <div className="terms-content">
                <p>
                    Welcome to Deep Finance Company's insurance plan. Kindly review the following terms and conditions carefully before proceeding with your insurance application:
                </p>
                <ol>
                    <li>Treatment Coverage: Our insurance covers treatment expenses only if the treatment is availed at any of our network hospitals.</li>
                    <li>Validity: This insurance offer is only valid for the specific period outlined in your policy documents.</li>
                    <li>Pre-authorization: Prior approval from Deep Finance Company is required for any treatment to be covered under this insurance plan.</li>
                    <li>Medical Necessity: The treatment expenses will be covered only if they are deemed medically necessary by our approved healthcare professionals.</li>
                    <li>Exclusions: Any treatment expenses not directly related to the medical condition or not prescribed by our network hospital's medical professionals will not be covered.</li>
                    <li>Renewal: The policy can be renewed annually, subject to the terms and conditions specified in the renewal policy.</li>
                    <li>Claim Process: Any claims must be made within the stipulated time frame and as per the guidelines provided in the policy document.</li>
                    <li>Co-payment: A co-payment may be required as per the policy terms, and it will be communicated during the application process.</li>
                    <li>Change in Terms: Deep Finance Company reserves the right to modify these terms and conditions as deemed necessary, with prior notification to the policyholders.</li>
                    <li>Termination: The policy may be terminated in case of any breach of the terms and conditions by the policyholder.</li>
                </ol>
                <p>
                    By proceeding with the application, you agree to adhere to these terms and conditions. For any further queries or clarifications, please reach out to our customer support team.
                </p>
            </div>
            <button className="claim-button">Claim Now</button>
            <button className='contact-us-button'>contact us</button>
        </div>
        <br/><br/><br/><br/><br/><br/>
    </>
  )
}

export default Kavach