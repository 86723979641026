import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../asset/DeepLogo.png'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
function SignUp() {
  const navigate=useNavigate('/login')
  const [fullname,setFullName]=useState(null)
  const [mobile,setMobile]=useState(null)
  const [email,setEmail]=useState(null)
  const [referedby,setReferedBy]=useState(null)
  const [password,setPassword] =useState(null)
  const [check,setCheck]=useState(true)
  const [errMsg,setErrMsg]=useState('')
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validateMobile = (mobile) => {
    const re = /^[0-9]{10}$/;
    return re.test(mobile);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  const handleMobileChange = (event) => {
    const newMobile = event.target.value;
    setMobile(newMobile);

    if (!validateMobile(newMobile)) {
      setMobileError('Please enter a valid 10-digit mobile number');
    } else {
      setMobileError('');
    }
  };
  const submit=async ()=>{
    if(check===true){
      if(fullname && mobileError==='' && emailError==='' && referedby &&password){
        await axios.post('https://api.deepfinance.co.in/api/v1/user/register',{fullname:fullname,mobile:mobile,email:email,referedby:referedby,password:password}
        , {headers : {
          'Content-Type': 'application/json',
        }},
      ).then((res)=>{
          alert('registered successfully')
          setErrMsg('')
          navigate('/')
        }).catch((err)=>{
          console.log(err)}
        )
      }else{
        if(mobileError)
        {
          alert(mobileError)
        }else if(emailError)
        {
          alert(emailError)
        }else{
          setErrMsg('please fill all the detail')
        }
      }
    }else{
      setErrMsg('please check agree with t&c')
    }
  }
  return (
    <div  className='login'>
       <img className='logo' src={logo} alt='loading'></img>
        <p>Signup please</p>
        <input  className='log-input' type='text' placeholder='Full Name' onChange={(e)=>{setFullName(e.target.value)}}></input>
        <input  className='log-input' type='text' placeholder='Mobile' onChange={handleMobileChange}></input>
        <input  className='log-input'type='email' placeholder='Email' onChange={handleEmailChange}></input>
        <input  className='log-input' type='text' placeholder='Invite code' onChange={(e)=>{setReferedBy(e.target.value)}}></input>
        <input  className='log-input' type='password' placeholder='Create Password' onChange={(e)=>{setPassword(e.target.value)}}></input>
        <strong><input type='checkbox' checked={check} onChange={()=>{check===true?setCheck(false):setCheck(true)}}></input>agree with terms and conditions.</strong>
        <button className='log-button-submit'  onClick={()=>{submit()}}>Register</button>
        <p style={{'color':'red'}}>{errMsg}</p>
        <p>Already have an Account ? </p><Link to='/'>Login here</Link>
    </div>
  )
}

export default SignUp