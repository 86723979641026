import React from 'react'
import OfferCard from './OfferCard'


function Offers() {
  return (<>
    <OfferCard></OfferCard>
    <div className='content-area'>
        <div>
    <br/>
    <strong>Our Membership Opportunities: Your Journey to Earnings</strong>

    <ul>
        <li><strong>🌟 Step 1: Join 10 Members</strong> <br/>
        Your initial step! When you refer 10 new members to our app, your monthly income will be <strong>₹1,000</strong>.<br/><br/></li>

        <li>
            <strong>🔥 Step 2: Join 20 Members</strong><br/>
            Your success is doubling now! By referring 20 new members, your monthly income will be <strong>₹2,500</strong>.<br/><br/>
        </li>

        <li>
            <strong>🏆 Step 3: Join 30 Members</strong><br/>
            Your effort is commendable! By referring 30 new members, you will earn <strong>₹4,000 per month</strong>.<br/><br/>
        </li>

        <li>
            <strong>🚀 Step 4: Join 40 Members</strong><br/>
            Now you're reaching new heights! By referring 40 new members, your monthly income will be <strong>₹5,500</strong>.<br/><br/>
        </li>

        <li>
            <strong>💎 Step 5: Join 50 Members</strong><br/>
            You're on a big mission now! By referring 50 members, you'll earn <strong>₹7,000 every month</strong>.<br/><br/>
        </li>

        <li>
            <strong>💼 Step 6: Join 60 Members</strong><br/>
            You're really moving towards the top! By referring 60 members, your monthly income will be <strong>₹8,500</strong>.<br/><br/>
        </li>

        <li>
            <strong>🌟 Step 7: Join 70 Members</strong><br/>
            Your dedication is commendable! By referring 70 new members, you'll earn <strong>₹10,000 every month</strong>.<br/><br/>
        </li>

        <li>
            <strong>🌠 Step 8: Join 80 Members</strong><br/>
            You're not just a member, you're a leader! By referring 80 new members, you'll earn <strong>₹11,500 per month</strong>.<br/><br/>
        </li>

        <li>
            <strong>💎 Step 9: Join 90 Members</strong><br/>
            Your determination is amazing! By referring 90 new members, you'll earn <strong>₹13,000 every month</strong>.<br/><br/>
        </li>

        <li>
            <strong>🏅 Step 10: Join 100 Members</strong><br/>
            You've reached the pinnacle of success! By referring 100 new members, you'll earn <strong>₹15,000 every month</strong>.<br/><br/>
        </li>
    </ul>
    <p>
        <strong>📈 Business Opportunities in the Modern Age:</strong> As soon as you refer 250 members, you can become part of our business opportunity and earn <strong>₹50,000 every month</strong>. In addition, you will also join various clubs:
        <ul>
            <li>🥈 <strong>Silver Club</strong> - 500 members - <strong>₹1,00,000 per month</strong></li>
            <li>🥇 <strong>Golden Club</strong> - 750 members - <strong>₹1,50,000 per month</strong></li>
            <li>💎 <strong>Diamond Club</strong> - 1000 members - <strong>₹2,00,000 per month</strong></li>
        </ul>
     
    </p>
  
        </div>

        <div>
            <br/>
        <strong>हमारे सदस्यता अवसर: आपकी आय का सफर</strong>

        <ul>
            <li><strong>🌟चरण 1: 10 सदस्य जोड़ें</strong> <br/>
            आपका आरंभिक कदम! जब आप 10 नए सदस्यों को हमारे ऐप में पेश करें, तो आपकी मासिक आय <strong>₹1,000</strong> होगी।
            <br/><br/></li>


            <li>
            

                <strong>🔥 चरण 2: 20 सदस्य जोड़ें</strong><br/>
                अब आपका सफलता दोगुना हो रहा है! 20 नए सदस्यों को पेश करने से आपकी मासिक आय <strong>₹2,500</strong>  होगी।<br/><br/>
              
            </li>


            <li>
                <strong>🏆 चरण 3: 30 सदस्य जोड़ें</strong><br/>
                आपका प्रयास अपनी अलग बात है! 30 नए सदस्यों को पेश करने पर आपको लाभ <strong>₹4,000 प्रति महीना</strong> होगा।<br/><br/>
              
            </li>

            <li>
                <strong>🚀 चरण 4: 40 सदस्य जोड़ें</strong><br/>
                अब आप नए ऊँचाइयों को छू रहे हैं! 40 नए सदस्यों को पेश करने से आपकी मासिक आय <strong>₹5,500</strong> होगी।<br/><br/>
             
            </li>

            <li>
                <strong>💎 चरण 5: 50 सदस्य जोड़ें</strong><br/>
                अब आप बड़े मुद्दे पर हैं! 50 सदस्यों को पेश करके आप हर महीने <strong>₹7,000</strong> कमाएंगे।<br/><br/>
             
            </li>
           







            <li>
                <strong> 💼 चरण 6: 60 सदस्य जोड़ें</strong><br/>
                आप वाकई ऊंचाइयों की ओर बढ़ रहे हैं! 60 सदस्यों को पेश करके, आपकी मासिक आय <strong>₹8,500</strong> होगी।<br/><br/>
                <i></i>
            </li>

            <li>
                <strong>🌟 चरण 7: 70 सदस्य जोड़ें</strong><br/>
                आपका समर्पण सराहनीय है! 70 नए सदस्यों को पेश करने पर, आप हर महीने <strong>₹10,000</strong> कमाएंगे।<strong></strong><br/><br/>
                <i></i>
            </li>

            <li>
                <strong>🌠 चरण 8: 80 सदस्य जोड़ें</strong><br/>
                आप बस सदस्य नहीं, आप नेता हैं! 80 नए सदस्यों को पेश करने पर, आपको प्रति महीने <strong>₹11,500</strong> होंगे।<strong></strong><br/><br/>
             
            </li>
           

            <li>
                <strong> 💎 चरण 9: 90 सदस्य जोड़े</strong><br/>
                आपकी निष्कर्षा अद्भुत है! 90 नए सदस्यों को पेश करने पर, आप हर महीने <strong>₹13,000</strong> कमाएंगे।<strong></strong><br/><br/>
               
            </li>

            <li>
                <strong>🏅 चरण 10: 100 सदस्य जोड़ें</strong><br/>
                आप सफलता की चोटी पर पहुँच चुके हैं! 100 नए सदस्यों को पेश करने पर, आप हर महीने <strong>₹15,000</strong> कमाएंगे।<strong></strong><br/><br/>
             
            </li>

        </ul>
        <p>


        <strong>📈 मोडर्न युग में व्यापारिक अवसर</strong> :
    जैसे ही आप 250 सदस्यों को पेश करेंगे, तो आप हमारे साथ व्यापारिक अवसर का हिस्सा बन सकते हैं और हर महीने <strong>₹50,000</strong>कमा सकते हैं। 
    इसके साथ ही, आप विभिन्न क्लबों में भी शामिल होंगे:
    <ul>
        <li>🥈 <strong>सिल्वर क्लब</strong> - 500 सदस्य - <strong>₹1,00,000 प्रति महीना</strong></li>
        <li>🥇 <strong>गोल्डन क्लब</strong> - 750 सदस्य - <strong>₹1,50,000 प्रति महीना</strong></li>
        <li>💎 <strong>डायमंड क्लब</strong> - 1000 सदस्य - <strong>₹2,00,000 प्रति महीना</strong></li>
    </ul>

अब अपनी कमाई बढ़ाने और Deep Finance के साथ नई ऊँचाइयों को छूने का मौका प्राप्त करें! 

</p>
<br/><br/><br/><br/><br/>
        </div>
    </div>
    </>
  )
}

export default Offers