import React, { useState } from 'react'
import {CopyOutlined } from '@ant-design/icons'
function ReferalCard({data}) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text) => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true);
  };
  return (
    <div className='card'>
    <div className='card-heading'><strong>Refer to build network</strong></div>
    <div className='card-content'>
        <div className='refer-content'>
            You can share your registered mobile number as a invite code to build you netwok. 
            <div className='code'>
            <span className='code-container'> {data[0].refercode} </span>
                <CopyOutlined onClick={() => copyToClipboard(
                  `Hi there! 👋 I came across this incredible website called Deep Finance that offers valuable insights into the world of finance and investment. It's a must-visit for all finance enthusiasts! 💼💸
                  🌐 Check out Deep Finance here: ${'www.deepfinance.co.in'} and use my invite code *${data[0].refercode}* to join the community! 🚀 #DeepFinance #FinancialInsights `)}/>
                {copied ? <span style={{ color: 'green', fontSize:'2vh' }}>Copied to clipboard!</span> : null}
                </div>
            </div>
    </div>
    
</div>
  )
}

export default ReferalCard