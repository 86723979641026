
import './App.css';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Login from './component/login/Login';
import SignUp from './component/login/SignUp';
import Home from './component/lending/Home';
import Profile from './component/profile/Profile';
import Offers from './component/offer/Offers';
import Funds from './component/funds/Funds';
import Gain from './component/gain/Gain';
import Team from './component/team/Team';
import Kavach from './component/kavach/Kavach';
import { useSelector } from "react-redux";
import { Toaster } from 'react-hot-toast';
import { Spin } from 'antd';
function App() {
  const { loading } = useSelector((state) => state.alerts);
  return (
    <div className='App'>
      {loading && (
        <div className="spinner-parent">
          <Spin size='large' style={{ 'color': 'black' }}/>
        </div>
      )}
      <Toaster
        position="top-center" // Used to adapt the animation
        reverseorder={false}
      />
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login></Login>}></Route>
        <Route path='/login' element={<Login></Login>}></Route>
        <Route path='/signup' element={<SignUp></SignUp>}></Route>
        <Route path='/home' element={<Home></Home>}></Route>
        <Route path='/profile' element={<Profile></Profile>}></Route>
        <Route path='/offer' element={<Offers></Offers>}></Route>
        <Route path='/funds' element={<Funds></Funds>}></Route>
        <Route path='/gain' element={<Gain></Gain>}></Route>
        <Route path='/team' element={<Team></Team>}></Route>
        <Route path='/kavach' element={<Kavach></Kavach>}></Route>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
