import React, { useState } from 'react'
import QRCode from 'qrcode.react';
import { Button } from 'antd';
function SchemeCard() {
    const [text, setText] = useState(5250);
    const handleChange = (event) => {
        setText(event.target.value);
      };
     
  const uriString = `upi://pay?pa=eazypay.0000073581@icici&tr=EZYS0000073581&cu=INR&mc=5999&am=${text}`; // replace with your UPI URI string
  const initiateUPIPayment = () => { window.open(uriString);
};
  

  return (
    <div className='scheme-card'>
      <strong>Do your Investment in Deep Finance </strong><br/>
      <select value={text} onChange={handleChange} className='scheme-select'>
        <option value="5250">Scheme 5250 for 100/day</option>
        <option value="10500">Scheme 10500 for 200/day</option>
        <option value="20250">Scheme 20250 for 350/day</option>
        <option value="40500">Scheme 40500 for 700/day</option>
        <option value="60750">Scheme 60750 for 1000/day</option>
      </select>
      <p>You have selected Scheme value : {text} </p>
      <Button ghost style={{ fontSize: '16px' }} onClick={initiateUPIPayment}>Pay with UPI</Button>
      <p>or</p>
      <p>pay using qr code</p>
      <dev style={{background:'white',padding:'20px'}}>
      <QRCode  value={uriString} />
      </dev>
      <p style={{color:'red'}}>Warning : due to some technical issues 
      <br/>phonepay payment not allowed</p>
      <br/><br/><br/><br/>
    </div>
  )
}

export default SchemeCard